// src/pages/AboutUs.js
import React from "react";
import { Container, Typography, Box } from "@mui/material";

const AboutUs = () => {
  return (
    <Container sx={{ textAlign: "center", paddingTop: "50px" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://cdn-icons-png.flaticon.com/512/4149/4149643.png"
          alt="Cloud Logo"
          width="150"
        />
      </Box>
      <Typography
        variant="h3"
        sx={{ marginTop: "20px", fontWeight: "bold", color: "#0B2F9F" }}
      >
        Aasritha Technologies
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "20px", color: "black" }}>
        We are a leading technology company specializing in software development
        and cloud-based solutions. Our mission is to provide innovative and
        reliable services to our clients. With a strong focus on customer
        satisfaction and cutting-edge technology, Aasritha Technologies is your
        trusted partner for all things digital.
      </Typography>
    </Container>
  );
};

export default AboutUs;
