// src/pages/PrivacyPolicy.js
import React from "react";
import { Container, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="body1" paragraph>
        Welcome to our Pincode Finder application. We value your privacy and are
        committed to protecting your personal information.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Data Collection and Usage
      </Typography>

      <Typography variant="body1" paragraph>
        Our application sources its data from the public MyGov.in platform. We
        do not collect or store any personal data from users. The information
        displayed in this application (such as Pincode data, office names, and
        related details) is fetched directly from MyGov.in, a publicly
        accessible government platform.
      </Typography>

      <Typography variant="h6" gutterBottom>
        External Data Sources
      </Typography>

      <Typography variant="body1" paragraph>
        The data used in this application is derived from MyGov.in. We do not
        modify or store the data but display it as provided by the MyGov.in
        platform. As the data is publicly accessible, we are not responsible for
        its accuracy or any subsequent changes that occur on the MyGov.in
        platform.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Cookies
      </Typography>

      <Typography variant="body1" paragraph>
        Our application does not use any cookies to track or store user
        information.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>

      <Typography variant="body1" paragraph>
        If you have any questions about this Privacy Policy, please contact us
        at: privacy@aasrithatechnologies.com.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
