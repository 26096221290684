// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Container, Button } from "@mui/material";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy"; // Import Privacy Policy

const App = () => {
  return (
    <Router>
      <AppBar position="static" sx={{ backgroundColor: "#161D6F" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Pincode Finder
          </Typography>
          <Button component={Link} to="/" color="inherit">
            Home
          </Button>
          <Button component={Link} to="/about" color="inherit">
            About Us
          </Button>
          <Button component={Link} to="/contact" color="inherit">
            Contact Us
          </Button>
          <Button component={Link} to="/privacy" color="inherit">
            Privacy Policy
          </Button>{" "}
          {/* Privacy Policy Link */}
        </Toolbar>
      </AppBar>

      <Container
        sx={{
          background: "linear-gradient(to bottom, #98DED9, #98DED9)",
          minHeight: "100vh",
          padding: 2,
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />{" "}
          {/* Route for Privacy Policy */}
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
