import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Paper,
  Box,
} from "@mui/material";
import Dropdown from "../components/Dropdown";
import PinCodeCard from "../components/PinCodeCard";
import pincodeData from "../data/pincodeData.json";

const Home = () => {
  const [circle, setCircle] = useState("");
  const [region, setRegion] = useState("");
  const [division, setDivision] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(null); // State for selected office

  // Get unique Circle Names
  const circles = [...new Set(pincodeData.map((item) => item["Circle Name"]))];

  // Filter regions based on selected circle
  const regions = circle
    ? [
        ...new Set(
          pincodeData
            .filter((item) => item["Circle Name"] === circle)
            .map((item) => item["Region Name"])
        ),
      ]
    : [];

  // Filter divisions based on selected region
  const divisions = region
    ? [
        ...new Set(
          pincodeData
            .filter((item) => item["Region Name"] === region)
            .map((item) => item["Division Name"])
        ),
      ]
    : [];

  // Filter offices based on selected division
  const offices = division
    ? pincodeData.filter((item) => item["Division Name"] === division)
    : [];

  useEffect(() => {
    setFilteredData(offices); // Save filtered office data when division is selected
    setSelectedOffice(null); // Reset selected office on dropdown change
  }, [division]);

  // Handle Autocomplete selection
  const handleOfficeSelection = (event, value) => {
    if (value) {
      const office = filteredData.find((item) => item["Office Name"] === value);
      setSelectedOffice(office); // Set the selected office to display the corresponding card
    } else {
      setSelectedOffice(null); // Reset selection if no office is selected
    }
  };

  return (
    <div>
      {/* SEO Meta Information */}
      <title>
        PIN Code Finder in India - Search by Area, City, District, and State
      </title>
      <meta
        name="description"
        content="Find accurate PIN codes for any area, city, district, or state in India. Explore PIN codes for all postal offices with the best PIN code finder tool in India. Search now!"
      />
      <meta
        name="keywords"
        content="PIN Code Finder India, Indian Postal Code Search, PIN Code Search by Area, Find Postal Codes by City, India ZIP Code Search, Area-wise PIN Code Finder, Post Office PIN Codes in India"
      />

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {/* Left Column: SEO Content */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5">
              Search PIN Codes by Popular Cities in India
            </Typography>
            <Typography variant="body1" paragraph>
              Get accurate postal codes for all areas in major cities like
              Delhi, Mumbai, Bangalore, Kolkata, and Chennai.
            </Typography>
            <Typography variant="h5">
              Introduction to PIN Codes in India
            </Typography>
            <Typography variant="body1" paragraph>
              A PIN code (Postal Index Number) is a 6-digit code used by India
              Post to identify specific locations and facilitate mail delivery.
              Each code is unique to an area, city, or postal office.
            </Typography>

            <Typography variant="h5">Structure of Indian PIN Codes</Typography>
            <Typography variant="body1" paragraph>
              Indian PIN codes are six digits long. The first digit represents
              the postal zone, the second digit refers to a sub-region or
              circle, the third identifies the sorting district, and the last
              three digits indicate the post office.
            </Typography>

            <Typography variant="h5">How PIN Codes are Allocated</Typography>
            <Typography variant="body1" paragraph>
              Postal zones are geographically divided into sub-zones, regional
              divisions, and sorting centers to allocate unique PIN codes for
              mail sorting and delivery.
            </Typography>

            <Typography variant="h5">
              Importance of PIN Codes in E-Commerce
            </Typography>
            <Typography variant="body1" paragraph>
              E-commerce relies on precise PIN codes for accurate delivery,
              estimated timelines, and optimized shipping routes, helping ensure
              timely deliveries.
            </Typography>

            <Typography variant="h5">
              Popular Cities and Their PIN Code Systems
            </Typography>
            <Typography variant="body1" paragraph>
              Cities like Delhi, Mumbai, Bangalore, and others have extensive
              and well-organized PIN code systems to support the population and
              business needs.
            </Typography>

            <Typography variant="h5">Delhi PIN Code Search</Typography>
            <Typography variant="body1" paragraph>
              North Delhi: Chandni Chowk (110006), South Delhi: Hauz Khas
              (110016), West Delhi: Janakpuri (110058).
            </Typography>

            <Typography variant="h5">Mumbai PIN Code Search</Typography>
            <Typography variant="body1" paragraph>
              South Mumbai: Colaba (400005), Western Suburbs: Andheri (400053),
              Navi Mumbai: Vashi (400703).
            </Typography>

            <Typography variant="h5">Bangalore PIN Code Search</Typography>
            <Typography variant="body1" paragraph>
              Central: MG Road (560001), East: Whitefield (560066), North:
              Yelahanka (560064).
            </Typography>

            <Typography variant="h5">Kolkata PIN Code Search</Typography>
            <Typography variant="body1" paragraph>
              Central Kolkata: BBD Bagh (700001), South: Ballygunge (700019),
              Suburban: Salt Lake City (700091).
            </Typography>

            <Typography variant="h5">Chennai PIN Code Search</Typography>
            <Typography variant="body1" paragraph>
              North Chennai: Royapuram (600013), South: Velachery (600042),
              Central: Egmore (600008).
            </Typography>

            <Typography variant="h5">Hyderabad PIN Code Search</Typography>
            <Typography variant="body1" paragraph>
              Central Hyderabad: Abids (500001), North: Secunderabad (500003),
              IT hubs: Gachibowli (500032).
            </Typography>

            <Typography variant="h5">Pune PIN Code Search</Typography>
            <Typography variant="body1" paragraph>
              Central: Shivaji Nagar (411005), West: Kothrud (411038), East:
              Viman Nagar (411014).
            </Typography>

            <Typography variant="h5">Ahmedabad PIN Code Search</Typography>
            <Typography variant="body1" paragraph>
              Central: Ellis Bridge (380006), West: Satellite (380015), East:
              Maninagar (380008).
            </Typography>

            <Typography variant="h5">Jaipur PIN Code Search</Typography>
            <Typography variant="body1" paragraph>
              Central: Bapu Bazaar (302003), South: Malviya Nagar (302017),
              North: Jhotwara (302012).
            </Typography>

            <Typography variant="h5">Searching PIN Codes Online</Typography>
            <Typography variant="body1" paragraph>
              Search for PIN codes using official postal websites or third-party
              apps to ensure accuracy and timely delivery.
            </Typography>

            <Typography variant="h5">
              Benefits of Knowing City PIN Codes
            </Typography>
            <Typography variant="body1" paragraph>
              Correct PIN codes streamline delivery, improve e-commerce
              accuracy, and ensure efficient communication.
            </Typography>

            <Typography variant="h5">
              Frequently Asked Questions (FAQs)
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>How Can I Search for PIN Codes by City?</strong> Use India
              Post's official site or mobile apps.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Why Do Cities Have Multiple PIN Codes?</strong> Large
              cities are divided for better postal service efficiency.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>What Happens If I Use the Wrong PIN Code?</strong> Delays
              or misdirected mail may occur.
            </Typography>
          </Paper>
        </Grid>

        {/* Right Column: Search Controls */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            <Grid container spacing={2} justifyContent="center">
              {/* Circle Name Dropdown */}
              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  label="Circle Name"
                  value={circle}
                  onChange={setCircle}
                  options={circles}
                  sx={{ minWidth: "200px" }}
                />
              </Grid>

              {/* Region Name Dropdown */}
              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  label="Region Name"
                  value={region}
                  onChange={setRegion}
                  options={regions}
                  sx={{ minWidth: "200px" }}
                />
              </Grid>

              {/* Division Name Dropdown */}
              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  label="Division Name"
                  value={division}
                  onChange={setDivision}
                  options={divisions}
                  sx={{ minWidth: "200px" }}
                />
              </Grid>
            </Grid>
          </Paper>

          {/* Autocomplete for Office Names */}
          {circle && region && division && filteredData.length > 0 && (
            <Box sx={{ margin: "20px auto", maxWidth: "600px" }}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Autocomplete
                  options={filteredData.map((item) => item["Office Name"])}
                  onChange={handleOfficeSelection}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Office Name"
                      variant="outlined"
                    />
                  )}
                />
              </Paper>
            </Box>
          )}

          {/* Display the selected office card or all cards */}
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            {selectedOffice ? (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <PinCodeCard
                  officeData={selectedOffice}
                  sx={{ height: "100%" }}
                />
              </Grid>
            ) : (
              filteredData.map((officeData, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <PinCodeCard
                    officeData={officeData}
                    sx={{ height: "100%" }}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
