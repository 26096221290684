// src/components/PinCodeCard.js
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const PinCodeCard = ({ officeData }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(officeData.Pincode);
    alert("Pincode copied to clipboard!");
  };

  return (
    <Card
      sx={{
        margin: "10px",
        border: "2px solid #ff9800", // Different border color (orange)
        borderRadius: "15px", // Rounded corners
        backgroundColor: "#f5f5f5", // Card background color
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)", // Zoom effect on hover
        },
        cursor: "pointer",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "inherit" }} // No background color for Office Name
        >
          {officeData["Office Name"]}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "10px",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: "#0B2F9F" }}
          >
            {officeData.Pincode}
          </Typography>
          <Tooltip title="Copy to Clipboard">
            <IconButton onClick={copyToClipboard} color="primary">
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PinCodeCard;
