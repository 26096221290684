// src/pages/ContactUs.js
import React from "react";
import { Container, Typography } from "@mui/material";

const ContactUs = () => {
  return (
    <Container sx={{ textAlign: "center", paddingTop: "50px" }}>
      <Typography variant="h4" sx={{ color: "#0B2F9F" }}>
        Contact Us
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "20px", color: "black" }}>
        Reach out to us at <strong>contact@aasrithatechnologies.com</strong>
      </Typography>
    </Container>
  );
};

export default ContactUs;
